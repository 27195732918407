import React from "react"
import { Helmet } from "react-helmet"

const search = () => {
  return (
    <div>
      <Helmet>
      <script type="text/javascript" src="https://widget.clutch.co/static/js/widget.js"></script>
      </Helmet>

      <div className="gcse-search"></div>
    </div>
  )
}


export default search